import { serverHost } from '../redux/reducers/reducer';
import axios from 'axios';

export default async function getAllOnlineCarts() {
    const server = serverHost();
    try {
        const response = await axios.get(server + `/utils/getAllOnlineCarts`);

        if (response.status === 200) {
            const data = response.data;
            // Convert object with numeric keys to array
            const carsArray = Object.keys(data).map(key => data[key]);
            return carsArray;
        } else {
            console.log("ERROR getAllOnlineCarts");
            return [];
        }
    } catch (error) {
        console.log("ERROR getAllOnlineCarts", error);
        return [];
    }
}
