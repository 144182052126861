import renderCustomAlert from "../components/customAlert/customAlert";
import { AppText } from "../components/multiLanguage/multiLanguage";
import newDestinationReqForCart from "../services/newDestinationForCart";
import {cartInPointAlert, somethingWentWrongAlert,pathNotFoundAlert,userHasNoReservationAlert,cartIsOfflineAlert,userInUseAlert} from "./renderAlerts/calcPathAlerts";

const confirmDestination = async ({
    p_poi_location,
    p_poi_name,
    setReservation,
    setUserFarFromRoad,
    setSearchPath,
    language,
    connectionError,
    user_id,
}) => {


    setSearchPath(true);

    try {
        const confirmDestinationResponse = await newDestinationReqForCart(user_id, p_poi_location, p_poi_name, connectionError);
        
        setSearchPath(false);
        
        if (confirmDestinationResponse !== 'error') {
            const data = confirmDestinationResponse.reservationDetails;
            if (data) {
                if (data.resultCode === 'cart_in_point' ) {
                    cartInPointAlert(language)
                } else if (data.resultCode === 'destination far from any roads' ) {
                    setUserFarFromRoad(true);
                } else if (data.resultCode === 'path_not_found') {
                    pathNotFoundAlert(language);
                } else if (data === 'no_user'){
                    userHasNoReservationAlert(language);
                } else if (data === 'user_in_use'){
                    userInUseAlert(language);
                }else if (data === 'cart_is_offline'){
                    cartIsOfflineAlert(language);
                }else if (data.resultCode === 'path_found') {
                    setReservation(data.reservationDetails);
                    return true;
                }
            } else  {
                somethingWentWrongAlert(language);
            }
        }else{
            somethingWentWrongAlert(language);
        }
        return false;
    }catch (error) {
        console.error('Error:', error);
        setSearchPath(false);
        somethingWentWrongAlert(language);
    }

    return false;
};

export default confirmDestination;
