import { useState, useEffect } from 'react';

const useGeoLocation = (isDemoUser) => {
  const accuracyThresholdWaitTimeInSeconds = 5;
  const options = { enableHighAccuracy: true, timeout: 1000, maximumAge: 0 };
  const accuracyThreshold = 7; 
  const [userLocation, setUserLocation] = useState(null);
  const [locationAccuracy, setLocationAccuracy] = useState(null);
  const [locationError, setLocationError] = useState(null);
  const [locationEnabled, setLocationEnabled] = useState(true);
  const [timeoutId, setTimeoutId] = useState(null);
  const [isFinishWatchUserLocation, setIsFinishWatchUserLocation] = useState(false);
  const [intervalId, setIntervalId] = useState(null);

  const startWatchUserLocation = () => {
    setIsFinishWatchUserLocation(false);
    // setLocationAccuracy(null);
    // setUserLocation(null);
    setTimeoutId(null);
    setLocationError(null);
    watchLocation();
  };

  const stopWatchingUserLocation = (p_intervalId= intervalId) => {
    if(p_intervalId){
     clearInterval(p_intervalId);      
     setIntervalId(null);
    } 
    setIsFinishWatchUserLocation(true);
  };

  const watchLocation = () => {
    if(isDemoUser){
      console.log("DEMO USER - DEFAULT POINT");
      stopWatchingUserLocation();
      setLocationAccuracy(5)
      return;
    }
    if (!locationEnabled) return;

    let checker =0;

    const intervalIdLocal = setInterval(() => {
      
      if(checker === (accuracyThresholdWaitTimeInSeconds *2) ){
        stopWatchingUserLocation(intervalIdLocal);
        return;
      }
    
     navigator.geolocation.getCurrentPosition(
        (position) => {

          const { latitude: lat, longitude: lng, accuracy: currentAccuracy } = position.coords;
          setLocationAccuracy(currentAccuracy);
          setUserLocation({ lat, lng } );
          
          if (currentAccuracy <= accuracyThreshold) {
            console.log("accuracy threshold")
            stopWatchingUserLocation(intervalIdLocal );
            return;
          }
        },
        (e) => {
          if (e.code === e.PERMISSION_DENIED) {
            // Geolocation is disabled by the user
            stopWatchingUserLocation(intervalIdLocal );
            setLocationEnabled(false);
          }
          console.log("Error Callback: ", e);
          setLocationError(e.message);
        },
        options
      );

      checker++;
    },500)

    setIntervalId(intervalIdLocal);

  };

  const requestUserLocationPermission = () => {
    if (!navigator.geolocation) {
      setLocationError('Geolocation API not available');
      setLocationEnabled(false);
      return;
    }
  
    navigator.geolocation.getCurrentPosition(
      (position) => {
        // Permission granted
        setLocationEnabled(true);

      },
      (error) => {
        if (error.code === error.PERMISSION_DENIED) {
          // Permission denied
          setLocationEnabled(false);
          setLocationError('Location access has been denied. Please enable location access in your browser settings.');
        } else {
        }
      },
      options
    );
  };
  

  useEffect(() => {
    
    if (!navigator.geolocation) {
      setLocationError('Geolocation API not available');
      // console.error('Geolocation API - not available');
      setLocationEnabled(false);
    }
  }, []);

  useEffect(()=>{
    if (!locationEnabled) {
      stopWatchingUserLocation();
      return;
    }
   
    watchLocation();
    return () => {
      if (timeoutId !== null) {
        clearTimeout(timeoutId);
        setTimeoutId(null);
      }
    };
  },[locationEnabled])

  return [userLocation, locationAccuracy, locationError,setLocationError, startWatchUserLocation, stopWatchingUserLocation, isFinishWatchUserLocation, locationEnabled,requestUserLocationPermission];
};

export default useGeoLocation;
